/* eslint-disable @typescript-eslint/no-explicit-any */
export const registrations = [
    {
        name: 'ApartmentDetails',
        import: () => import('@stories/Widgets/ApartmentDetails/ApartmentDetails'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Navigation',
        import: () => import('@stories/Widgets/Global/Navigation/Navigation'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'Footer',
        import: () => import('@stories/Widgets/Global/Footer/Footer'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'Homepage',
        import: () => import('@stories/Widgets/Homepage/Homepage'),
        config: {
            hydrate: 'always',
        },
    },
    {
        name: 'InnerPageHero',
        import: () => import('@stories/Widgets/InnerPageHero/InnerPageHero'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'LoginForm',
        import: () => import('@stories/Widgets/LoginForm/LoginForm'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'NotificationBanner',
        import: () => import('@stories/Widgets/Global/NotificationBanner/NotificationBanner'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'RichText',
        import: () => import('@stories/Widgets/RichText/RichText'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SplitContent',
        import: () => import('@stories/Widgets/SplitContent/SplitContent'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'SignpostCards',
        import: () => import('@stories/Widgets/SignpostCards/SignpostCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'FeatureCards',
        import: () => import('@stories/Widgets/FeatureCards/FeatureCards'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'StackerPlan',
        import: () => import('@stories/Widgets/StackerPlan/StackerPlan'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'ErrorDetail',
        import: () => import('@stories/Widgets/ErrorDetail/ErrorDetail'),
        config: {
            hydrate: 'in-view',
        },
    },
    {
        name: 'GatewayFilm',
        import: () => import('@stories/Widgets/GatewayFilm/GatewayFilm'),
        config: {
            hydrate: 'always',
        },
    },
];
